import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Inspector } from 'react-dev-inspector';
import not404 from './container/404/index';
import not500 from './container/500/index';
import Home from './container/home/index';
import LoginIndex from './container/login/index';
import Help from './container/help/index';

import { hasRoles } from './utils/authority';

import './app.less';

const isDev = ['local', 'dev', 'development', 'test', 'preview'].includes(
  process.env.REACT_APP_API,
);
@withRouter
class App extends Component {
  async componentDidMount() {
    const pathname = this.props.location.pathname;
    if (pathname === '/login' || pathname === '/help' || pathname === '/privacy') {
      // this.props.history.push(location.pathname)
    } else {
      if (!hasRoles()) {
        this.props.history.push('/login');
      }
    }
  }

  render() {
    return (
      <div className='app'>
        <Switch>
          <Route exact path='/login' component={LoginIndex}></Route>
          <Route exact path='/help' component={Help}></Route>
          <Route path='/exception/404' component={not404}></Route>
          <Route path='/exception/500' component={not500}></Route>
          <Route path='/' component={Home}></Route>
          <Route component={not404}></Route>
          <Route component={not500}></Route>
        </Switch>
        {isDev && (
          <Inspector
            keys={['control', 'shift', 'command', 'c']}
            disableLaunchEditor={true}
            onClickElement={({ codeInfo }) => {
              if (!codeInfo?.absolutePath) return;
              const { absolutePath, lineNumber, columnNumber } = codeInfo;
              window.open(`cursor://file${absolutePath}:${lineNumber}:${columnNumber}`);
            }}
          >
            <div />
          </Inspector>
        )}
      </div>
    );
  }
}

export default App;
