//商行管理 -- 商行管理（查看详情）
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Button, Tabs, Row, Col, Icon, Dropdown, Menu, Modal, message, Spin } from 'antd';

import PageHeaderLayout from '../../../component/page-header-layout/index';
import { hasPermissionCode } from '../../../utils/authority';
import {
  STALL_SYSTEM_TYPE,
  STALL_TYPES,
  STALL_SALE_TYPE,
  STALL_TYPE,
  BUSINESS_TYPE,
} from '../../../utils/deviceType';
import TestStall from '../../../common/images/icon/testStall.png';
import PreStall from '../../../common/images/icon/preStall.png';
import EditPng from '../../../common/images/icon/edit.png';
import './detail.less';

import {
  marketList,
  stallDetails,
  cleanStallDetail,
  getStallManger,
  restPassword,
  getAllStallEmploy,
  enableMarket,
  loginOutAll,
  solidifyStock,
  copyStall,
} from '../../../state/action/stall';

import Statistics from './stall/statistics';
import DeviceManagement from './stall/deviceManagement';
import ConfigManagement from './stall/configManagement';
import EmployeeManage from './stall/employeeManage';
import ContractManagement from './stall/contractManagement';
import ConvertType from './stall/convertType';
import ConvertSaleMode from './stall/convertSaleMode';
import UpdateStall from './stall/updateStall';
import TransferStall from './stall/transferStall';
import BasketSettings from './stall/basketSettings';
import AddtionFeeConfig from './stall/addtionFeeConfig';
import SettlementFee from './stall/settlementFee';
import SignatureAmount from './stall/SignatureAmount';
import ConfigList from './stall/configList';
import StallRoleManager from './stall/stallRoleManager';
import StallProductList from './stall/stallProductList';
import RecommendStallList from './stall/recommendStallList';
import Serve from './stall/serve';
import CashierAccountConfiguration from './stall/cashierAccountConfiguration';

import ButtonUploadFile from './ButtonUploadFile/index';
import BuyerList from './stall/BuyerList/index';

const { confirm } = Modal;
const { TabPane } = Tabs;

@connect(
  (state) => {
    return { stall: state.stall };
  },
  {
    marketList,
    stallDetails,
    cleanStallDetail,
    getStallManger,
    restPassword,
    getAllStallEmploy,
    enableMarket,
    loginOutAll,
    solidifyStock,
    copyStall,
  },
)
@Form.create()
class StallManagementDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.stallId = this.props.location.state && this.props.location.state.stallId;
    this.state = {
      stallVisible: false, //修改档主
      transferVisible: false, //档主权限移交
      convertTypeVisible: false, //转换类型
      convertSaleModeVisible: false, //转换管理模式
      previewVisible: false,
      activeKey: '1',
      loading: false,
    };

    //线清理商行数据
    this.props.cleanStallDetail();
  }

  componentDidMount() {
    this.getMarketList(); //获得市场列表
    this.getStallDetail({ id: this.stallId }); // 查看商行详情
  }

  //返回商行详情对象
  getStallDetailValue = () => {
    const {
      stall: { stallDetails },
    } = this.props;

    return stallDetails;
  };

  // 获得市场列表
  async getMarketList() {
    await this.props.marketList({});
  }

  // 查看商行详情
  async getStallDetail(params) {
    this.setState({ loading: true });
    await this.props.stallDetails(params);
    this.setState({ loading: false });
  }

  //刷新商行，在录入合同时，商行状态自动改为已签约
  refreshStall = () => {
    const stall = this.getStallDetailValue();
    this.getStallDetail({ id: stall.id }); // 查看商行详情
  };

  //按商行id加载详情
  reloadStallById = async (stallId) => {
    //按新id重新加载页面
    this.props.history.replace({
      pathname: '/booth/stallManager/stallManagementDetail',
      state: { stallId },
    });
    window.location.reload();
  };

  //返回
  back = (e) => {
    e.preventDefault();
    this.gotoBack();
  };

  gotoBack = () => {
    this.props.history.push({ pathname: '/booth/stallManager/', state: {} });
  };

  //编辑商行信息
  toEdit = () => {
    const {
      stall: { stallDetails },
    } = this.props;
    this.props.history.push({
      pathname: '/booth/stallManager/NewStallManage',
      state: { record: stallDetails, status: 'Detail' },
    });
  };

  //重置档主密码
  resetPwd(record) {
    const that = this;
    confirm({
      title: '是否重置档主密码为手机号后6位?',
      content: '',
      onOk() {
        const { personPhone } = record;
        that.restPassword({
          personPhone: personPhone,
        });
      },
      onCancel() {},
    });
  }
  //重置密码
  async restPassword(params) {
    const result = await this.props.restPassword(params);
    if (result.code === 200) {
      message.success('重置成功');
    } else {
      message.error(result.msg);
    }
  }

  //修改档主信息--获取档主信息
  async getStallManger(record) {
    const { id } = record; //personPhone
    await this.props.getStallManger({ id: id });
    this.setState({
      stallVisible: true,
    });
  }

  //关闭档主modal
  handleCancel = (result) => {
    if (result != null && result) {
      //关闭刷新
      const stall = this.getStallDetailValue();
      this.getStallDetail({ id: stall.id });
    }
    this.setState({ stallVisible: false });
  };

  //移交档主权限
  transferStall = (flag, result) => {
    const stall = this.getStallDetailValue();

    if (flag) {
      //弹开modal框
      //获取商行下所有员工信息
      this.getAllStallEmploy({ stallId: stall.id });
    }
    if (result != null && result) {
      //关闭刷新
      this.getStallDetail({ id: stall.id });
    }
    this.setState({
      transferVisible: flag,
    });
  };

  //获取商行下所有员工信息
  async getAllStallEmploy(params) {
    await this.props.getAllStallEmploy(params);
  }

  //转换商行版本
  handleCancelForConvertType = () => {
    this.setState({ convertTypeVisible: false });
  };
  // 转换管理模式
  handleCancelForConvertSaleMode = () => {
    this.setState({ convertSaleModeVisible: false });
  };

  //启用和注销商行
  enableMarket(record) {
    const that = this;
    confirm({
      title: <div>{record.enable ? '注销商行' : '启用商行'}</div>,
      content: (
        <div>
          {record.enable ? (
            <div>
              <div>注销商行后，商行用户将无法登录及使用</div>
              <div>确定要注销【{record.name}】吗？</div>
            </div>
          ) : (
            <div>
              <div>确定要启用【{record.name}】么？</div>
            </div>
          )}
        </div>
      ),
      async onOk() {
        const stall = that.getStallDetailValue();
        const { id } = stall;
        await that.props.enableMarket({
          id,
          enable: !stall.enable,
        });
        await that.reloadStallById(id);
      },
      onCancel() {},
    });
  }

  //注销登录
  loginOutAll(record) {
    const that = this;
    confirm({
      title: <div>注销登录</div>,
      content: (
        <div>
          <div>是否注销商行用户的登录状态</div>
        </div>
      ),
      async onOk() {
        const { id } = record;
        await that.props.loginOutAll({
          stallId: id,
        });
        await that.getStallDetail({ id });
      },
      onCancel() {},
    });
  }
  //固化库存
  solidifyStock(record) {
    const that = this;
    confirm({
      title: <div>固化库存</div>,
      content: (
        <div>
          <div>
            前置条件：
            <br />
            1.商行为按批次卖商行
            <br />
            2.商行配置【财务端进销存报表】
            <br />
            操作后固化该商行3个月前的库存数据
            <br />
          </div>
        </div>
      ),
      async onOk() {
        const { id } = record;
        const result = await that.props.solidifyStock(id);
        if (result.code === 200) {
          message.success('固化成功');
        }
      },
      onCancel() {},
    });
  }

  //复制商行
  copyStall(record) {
    const that = this;
    const { id, name } = record;

    confirm({
      title: <div>复制商行</div>,
      content: (
        <div>
          <div>
            是否需要复制{name}
            ，复制后此商行将注销，请谨慎操作。（若老商行为自动交账商行，复制的商行将不会自动交账，请更改配置或联系产研添加
          </div>
        </div>
      ),
      async onOk() {
        const { code, data } = await that.props.copyStall(id);

        if (code === 200) {
          await that.reloadStallById(data);
        }
      },
      onCancel() {},
    });
  }

  render() {
    const { previewVisible, activeKey, loading } = this.state;
    const {
      stall: { stallDetails = {}, stallMangerInfoVo, marketData = [] },
    } = this.props;
    const layout = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };
    const layout1 = { xs: 24, sm: 12, md: 12, lg: 8, xl: 8 };

    const resetPassword = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.resetPwd(stallDetails);
        }}
        style={{ textAlign: 'center' }}
      >
        重置密码
      </a>
    );
    const updateStall = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.getStallManger(stallDetails);
        }}
        style={{ textAlign: 'center' }}
      >
        修改档主
      </a>
    );
    const transferStall = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.transferStall(true);
        }}
        style={{ textAlign: 'center' }}
      >
        移交档主权限
      </a>
    );
    const convertType = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.setState({ convertTypeVisible: !this.state.convertTypeVisible });
        }}
        style={{ textAlign: 'center' }}
      >
        转换商行版本
      </a>
    );
    const convertSaleMode = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.setState({ convertSaleModeVisible: !this.state.convertSaleModeVisible });
        }}
        style={{ textAlign: 'center' }}
      >
        转换管理模式
      </a>
    );
    const loginOutAll = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.loginOutAll(stallDetails);
        }}
        style={{ textAlign: 'center' }}
      >
        注销登录
      </a>
    );
    const solidifyStock = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.solidifyStock(stallDetails);
        }}
        style={{ textAlign: 'center' }}
      >
        固化库存
      </a>
    );
    const stallCopy = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.copyStall(stallDetails);
        }}
        style={{ textAlign: 'center' }}
      >
        复制商行
      </a>
    );

    const styleValue = stallDetails.enable
      ? { textAlign: 'center', color: '#FF0000' }
      : { textAlign: 'center' };
    const enableMarket = (
      <a
        href='/'
        onClick={(e) => {
          e.preventDefault();
          this.enableMarket(stallDetails);
        }}
        style={styleValue}
      >
        {stallDetails.enable ? '注销商行' : '启用商行'}
      </a>
    );
    // 点击更多的菜单
    const menu = (
      <Menu>
        <Menu.Item>{resetPassword}</Menu.Item>
        <Menu.Item>{updateStall}</Menu.Item>
        <Menu.Item>{transferStall}</Menu.Item>
        {hasPermissionCode('01.003.006') ? <Menu.Item>{convertType}</Menu.Item> : null}
        <Menu.Item>{convertSaleMode}</Menu.Item>
        <Menu.Item>{loginOutAll}</Menu.Item>
        <Menu.Item>{solidifyStock}</Menu.Item>
        <Menu.Item>{stallCopy}</Menu.Item>
        {hasPermissionCode('01.003.004') ? <Menu.Item>{enableMarket}</Menu.Item> : null}
      </Menu>
    );

    if (stallDetails) {
      if (stallDetails.type === 3) {
        stallDetails.type = 1;
      } else if (stallDetails.type === 4) {
        stallDetails.type = 2;
      }
    }

    return (
      <PageHeaderLayout>
        <Spin spinning={loading}>
          <div className='StallManagementDetail'>
            <Row className='backRow'>
              <a href='/' onClick={this.back}>
                <Icon type='left' /> 返回
              </a>
            </Row>
            <Row className='row1'>
              <Col {...{ xs: 24, sm: 24, md: 24, lg: 17, xl: 17 }}>
                <Row>
                  <Col {...layout} className='title'>
                    商行ID：{this.stallId || '- -'}
                    <span
                      className={`enable ${stallDetails.enable ? 'enable-true' : 'enable-false'}`}
                    >
                      {stallDetails.enable ? '启用' : '注销'}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col {...layout} className='title'>
                    商行名称：{stallDetails.name || '- -'}
                    <span className='color1'>（{stallDetails.shortName || '- -'}）</span>
                    <a
                      href='/'
                      onClick={(e) => {
                        e.preventDefault();
                        this.toEdit();
                      }}
                    >
                      <img src={EditPng} alt='' style={{ width: 28 }} />
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col {...layout1} className='colLabel'>
                    商行版本：
                    <span className='color2'>{STALL_TYPE.get(stallDetails.type) || '- -'}</span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    管理模式：
                    <span className='color2'>
                      {stallDetails.saleType ? STALL_SALE_TYPE.get(stallDetails.saleType) : '- -'}
                    </span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    有效期至：
                    <span className='color2'>
                      {stallDetails.serviceEndTime > 0
                        ? moment(stallDetails.serviceEndTime).format('YYYY-MM-DD')
                        : null}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col {...layout1} className='colLabel'>
                    联系人：
                    <span className='color2'>{stallDetails.personName}</span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    归属地：
                    <span className='color2'>{`${stallDetails.provinceName || ''} ${
                      stallDetails.cityName || ''
                    } ${stallDetails.areaName || ''}`}</span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    所属市场：
                    <span className='color2'>{stallDetails.marketName || '- -'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col {...layout1} className='colLabel'>
                    联系电话：<span className='color2'>{stallDetails.phone || '- -'}</span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    创建时间：
                    <span className='color2'>
                      {stallDetails.createTime
                        ? moment(stallDetails.createTime).format('YYYY-MM-DD HH:mm')
                        : '- -'}
                    </span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    签约时间：
                    <span className='color2'>
                      {stallDetails.pactStartTime
                        ? moment(stallDetails.pactStartTime).format('YYYY-MM-DD HH:mm')
                        : '- -'}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col {...layout1} className='colLabel'>
                    商行分类：
                    <span className='color2'>
                      {STALL_TYPES.get(stallDetails.pactStatus) || '- -'}
                    </span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    系统类型：
                    <span className='color2'>
                      {stallDetails.systemType
                        ? STALL_SYSTEM_TYPE.get(stallDetails.systemType)
                        : '- -'}
                    </span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    营业时间：
                    <span className='color2'>{stallDetails.businessHours}</span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    商行类型：
                    <span className='color2'>
                      {BUSINESS_TYPE[stallDetails.businessType] || '蔬菜'}
                    </span>
                  </Col>
                  <Col {...layout1} className='colLabel'>
                    集团名称：
                    <span className='color2'>{stallDetails.orgName}</span>
                  </Col>
                  {stallDetails.businessLicense && (
                    <Col {...layout1} className='colLabel'>
                      营业执照：
                      <span
                        className='color2'
                        style={{ textDecoration: 'underline', cursor: 'pointer', color: 'green' }}
                        onClick={() => {
                          this.setState({
                            previewVisible: true,
                          });
                        }}
                      >
                        点击查看
                      </span>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col className='colLabel'>
                    商行地址：
                    <span className='color2'>{stallDetails.address || '- -'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='colLabel'>
                    单据显示市场：
                    {stallDetails.definedMarketName || '- -'}
                  </Col>
                </Row>
                <Row>
                  <Col className='colLabel'>
                    备注：
                    <span className='color2'>{stallDetails.stallRemark || '- -'}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='colLabel'>
                    原商行ID：
                    {stallDetails.parentStallId ? (
                      <span
                        className='color2'
                        style={{ textDecoration: 'underline', cursor: 'pointer', color: 'green' }}
                        onClick={() => {
                          this.reloadStallById(stallDetails.parentStallId);
                        }}
                      >
                        {stallDetails.parentStallId}
                      </span>
                    ) : (
                      '- -'
                    )}
                  </Col>
                </Row>
                <br />
              </Col>
              <Col
                {...{ xs: 24, sm: 24, md: 24, lg: 7, xl: 7 }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <ButtonUploadFile stallId={this.stallId} />
                  <Dropdown overlay={menu} trigger={['click']}>
                    <Button type='primary' style={{ background: '#1D9E60', width: 116 }}>
                      更多 <Icon type='down' />
                    </Button>
                  </Dropdown>
                </div>
                {stallDetails.type === 1 || stallDetails.type === 2 ? (
                  <img src={PreStall} alt='' style={{ width: 150 }} />
                ) : stallDetails.type === 3 || stallDetails.type === 4 ? (
                  <img src={TestStall} alt='' style={{ width: 150 }} />
                ) : null}
              </Col>
            </Row>
            <br />
            <Row className='row2'>
              <Tabs
                defaultActiveKey='1'
                activeKey={activeKey}
                onChange={(e) => {
                  this.setState({ activeKey: e });
                }}
              >
                <TabPane tab='数据统计' key='1'>
                  <Statistics stallId={this.stallId} />
                </TabPane>
                <TabPane tab='配置功能管理' key='7'>
                  <ConfigManagement stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='增值服务管理' key='13'>
                  <Serve stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='设备管理' key='2'>
                  <DeviceManagement stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='合同管理' key='3'>
                  <ContractManagement
                    stallId={this.stallId}
                    signSaleId={stallDetails.signSaleId || undefined}
                    stallType={stallDetails.pactStatus}
                    sign
                    marketId={stallDetails.marketId}
                    refreshStall={this.refreshStall}
                  />
                </TabPane>
                <TabPane tab='附加费用配置' key='6'>
                  <AddtionFeeConfig stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='筐子设置' key='4'>
                  {activeKey === '4' && (
                    <BasketSettings stallId={this.stallId} marketId={stallDetails.marketId} />
                  )}
                </TabPane>
                <TabPane tab='结算费用设置' key='5'>
                  <SettlementFee stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='商行员工管理' key='8'>
                  <EmployeeManage stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='支付柜台配置' key='9'>
                  <ConfigList stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='角色配置' key='10'>
                  <StallRoleManager stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                {hasPermissionCode('01.056.002') && (
                  <TabPane tab='签字额度' key='11'>
                    <SignatureAmount stallId={this.stallId} marketId={stallDetails.marketId} />
                  </TabPane>
                )}
                {hasPermissionCode('01.064.001') && (
                  <TabPane tab='货品列表' key='12'>
                    <StallProductList stallId={this.stallId} saleType={stallDetails.saleType} />
                  </TabPane>
                )}
                <TabPane tab='已介绍商行' key='14'>
                  <RecommendStallList stallId={this.stallId} marketId={stallDetails.marketId} />
                </TabPane>
                <TabPane tab='商行买家' key='16'>
                  {activeKey === '16' && (
                    <BuyerList stallId={this.stallId} marketId={stallDetails.marketId} />
                  )}
                </TabPane>
                {hasPermissionCode('01.066.001') && (
                  <TabPane tab='收银账户配置' key='15'>
                    <CashierAccountConfiguration
                      stallId={this.stallId}
                      marketId={stallDetails.marketId}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Row>

            {/**修改档主信息 */}
            <UpdateStall
              stallVisible={this.state.stallVisible}
              stallMangerInfoVo={stallMangerInfoVo}
              handleCancel={this.handleCancel}
            />
            {/**移交档主权限 */}
            <TransferStall
              transferVisible={this.state.transferVisible}
              record={stallDetails}
              handleCancelForTransfer={this.transferStall}
            />
            {/**转换系统类型 */}
            <ConvertType
              id={this.stallId}
              type={stallDetails.type}
              convertTypeVisible={this.state.convertTypeVisible}
              handleCancelForConvertType={this.handleCancelForConvertType}
              handleDetail={() => this.getStallDetail({ id: this.stallId })}
              marketData={marketData}
              stallDetails={stallDetails}
            />
            {/**转换商行版本 */}
            <ConvertSaleMode
              id={this.stallId}
              saleType={stallDetails.saleType}
              convertSaleModeVisible={this.state.convertSaleModeVisible}
              handleCancelForConvertSaleMode={this.handleCancelForConvertSaleMode}
              handleDetail={() => this.getStallDetail({ id: this.stallId })}
              stallDetails={stallDetails}
              marketData={marketData}
            />
          </div>
          {stallDetails.businessLicense && (
            <Modal
              width={800}
              className='picModal'
              visible={previewVisible}
              footer={null}
              onCancel={() => {
                this.setState({
                  previewVisible: false,
                });
              }}
            >
              <img alt='' style={{ width: '100%' }} src={stallDetails.businessLicense} />
            </Modal>
          )}
        </Spin>
      </PageHeaderLayout>
    );
  }
}
export default StallManagementDetail;
